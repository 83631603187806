<template>
  <section class="feedback-correction">
    <section class="filter">
      <el-form :inline="true" :model="form">
        <el-form-item label="时间">
          <el-date-picker
            v-model="form.time"
            type="date"
            size="small"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="客户名">
          <el-input v-model="form.name" placeholder="" size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleSubmit" :disabled="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="content" v-loading="loading">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="全部" name="first"></el-tab-pane>
        <el-tab-pane label="已处理" name="second"></el-tab-pane>
        <el-tab-pane label="未处理" name="third"></el-tab-pane>
        <el-tab-pane label="不处理" name="fourth"></el-tab-pane>
      </el-tabs>
      <div class="control">
        <el-button size="mini" @click="processed">处理</el-button>
        <!-- <el-button size="mini" @click="unprocessed">未处理</el-button> -->
        <el-button size="mini" @click="untreated">不处理</el-button>
      </div>
      <el-table
        :data="tableData"
        border element-loading-text="正在查询"
        style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column  :resizable="false" type="selection" align="center" width="39"></el-table-column>
        <el-table-column  :resizable="false"
          prop="linkman"
          label="联系人"
          width="100"
          align="center">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="contactInfo"
          label="电话"
          align="center"
          width="140">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="content"
          label="反馈" header-align="center">
          <template scope="{ row }">
            <span style="color: #3086FF;cursor: pointer;" @click="details(row)">{{ row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="createDate"
          align="center"
          width="200"
          label="日期" :formatter="dateFormat">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="status"
          align="center"
          width="120"
          label="状态">
          <template scope="{ row }">
            <span :style="{ color: row.status === 1 ? '#2EDC90' : row.status === 2 ? '#FF8F3D' : '#FF0000' }">{{ row.status === 1 ? '已处理' : row.status === 2 ? '不处理' : '未处理' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="sizeChange"
          @current-change="currentChange"
          :total="count">
        </el-pagination>
      </section>
      <el-dialog
        title="反馈详情"
        :visible.sync="dialogVisible"
        width="910px"
        :before-close="handleClose">
        <template>
          <span class="title">{{feedbackDetail?feedbackDetail.title:''}}</span>
          <div class="details">
            <p>[反馈]</p>
            <p>标题：{{feedbackDetail?feedbackDetail.title:''}}</p>
            <p>摘要：{{feedbackDetail?feedbackDetail.summary:''}}</p>
            <p>网站：{{feedbackDetail?feedbackDetail.sourceName:''}}</p>
            <p>
              网址：<el-link type="primary" :href="feedbackDetail.detailUrl" target="_blank">{{feedbackDetail.detailUrl}}</el-link>&nbsp;
              <el-link type="info" :href="feedbackDetail.url" target="_blank">查看原文 &gt;</el-link>
            </p>
          </div>
          <el-table :data="feedbackTable" border style="width: 100%">
            <el-table-column  :resizable="false" prop="linkman" label="接收人" width="180">
            </el-table-column>
            <el-table-column  :resizable="false" prop="contactInfo" label="反馈电话" width="180">
            </el-table-column>
            <el-table-column  :resizable="false" prop="content" label="反馈">
            </el-table-column>
            <el-table-column  :resizable="false" prop="createDate" label="发送时间"  width="180" :formatter="dateFormat">
            </el-table-column>
          </el-table>
        </template>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { initCorrectionFeedback, updateStatus, details } from '@/api/feedback'
export default {
  data () {
    return ({
      form: {
        time: '',
        name: ''
      },
      activeName: 'first',
      tableData: [],
      feedbackDetail: {
        title: 'title',
        summary: 'summary',
        sourceName: 'source',
        url: 'url'
      },
      feedbackTable: [],
      list: [],
      limit: 10,
      count: 0,
      status: '',
      currentPage: 1,
      loading:false,
      dialogVisible: false,
      multipleSelection: []
    })
  },
  methods: {
    handleClick (tab, event) {
      this.currentPage = 1
      if (tab.name === 'first') {
        this.status = ''
        this.initData()
      } else if (tab.name === 'second') {
        this.status = '1'
        this.initData()
      } else if (tab.name === 'third') {
        this.status = '0'
        this.initData()
      } else{
        this.status = '2'
        this.initData()
      }
    },
    async details (data) {
      this.dialogVisible = true
      this.feedbackTable.push(data)
      try {
        const res = await details({ id: data.id })
        this.feedbackDetail = res.data[0]
        this.feedbackDetail.detailUrl = 'http://localhost/#/monitor/detail/'+this.feedbackDetail.sn+'/1'
        // this.feedbackDetail.detailUrl = 'http://localhost:8020/#/monitor/detail/'+this.feedbackDetail.sn+'/1'
      } catch (err) {
        console.log(err)
      }
    },
    handleClose () {
      this.dialogVisible = false
      this.feedbackTable = []
    },
    async processed () {  // 已处理
      if (this.multipleSelection.length > 0) {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          this.list.push(this.multipleSelection[i].id)
        }
        try {
          const res = await updateStatus({ id: this.list, status: '1' })
          this.initData()
          this.list = []
        } catch (err) {
          console.log(err)
        }
      } else {
        // 弹窗提示请选择一条数据
        this.$alert('请选择一条数据', '提示', {
          confirmButtonText: '确定'
        });
      }
    },
    async untreated () {  // 不处理
      if (this.multipleSelection.length > 0) {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          this.list.push(this.multipleSelection[i].id)
        }
        try {
          const res = await updateStatus({ id: this.list, status: '2' })
          this.initData()
          this.list = []
        } catch (err) {
          console.log(err)
        }
      } else {
        // 弹窗提示请选择一条数据
        this.$alert('请选择一条数据', '提示', {
          confirmButtonText: '确定'
        });
      }
    },
    async unprocessed () {  // 未处理
      if (this.multipleSelection.length > 0) {
        for (var i = 0; i <= this.multipleSelection.length - 1; i++) {
          this.list.push(this.multipleSelection[i].id)
        }
        try {
          const res = await updateStatus({ id: this.list, status: '0' })
          this.initData()
          this.list = []
        } catch (err) {
          console.log(err)
        }
      } else {
        // 弹窗提示请选择一条数据
        this.$alert('请选择一条数据', '提示', {
          confirmButtonText: '确定'
        });
      }
    },
    async sizeChange (i) {
      this.currentPage = 1
      this.limit = i
      try {
        const res = await initCorrectionFeedback({ pageSize: this.limit, current: this.currentPage })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    },
    async currentChange (i) {
      this.currentPage = i
      try {
        const res = await initCorrectionFeedback({ pageSize: this.limit, current: this.currentPage })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    },
    async handleSubmit () {
      this.limit = 5
      this.currentPage = 1
      try {
        const res = await initCorrectionFeedback({ pageSize: this.limit, current: this.currentPage, linkman: this.form.name, createDate: this.form.time, status: this.status })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    },
    async initData () {
      this.loading = true
      try {
        const res = await initCorrectionFeedback({ pageSize: this.limit, current: this.currentPage, status: this.status })
        this.tableData = res.data
        this.count = res.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dateFormat (row,column) {
      var date = row.createDate
	    date = date.replace(/-/g,"/").substr(0,date.indexOf('.'))
      var t = new Date(date)
      var year=t.getFullYear(),
     　　 month=t.getMonth()+1,
     　　 day=t.getDate(),
    　　  hour=t.getHours(),
    　　  min=t.getMinutes(),
    　　  sec=t.getSeconds()
　　  var newTime = year + '-' +
    　　  (month < 10 ? '0'+ month : month) + '-' +
   　　   (day <10 ? '0' + day : day) + ' ' +
    　　  (hour <10 ? '0' + hour : hour) + ':' +
    　　  (min <10 ? '0' + min : min) + ':' +
    　　  (sec <10 ? '0' + sec : sec)
　　  return newTime
    }
  },
  async mounted () {
    this.initData()
  }
}
</script>

<style lang="scss" scoped="">
.feedback-correction {
  width: 100%;
  height: 100%;
  .filter {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 2px;
    padding-top: 15px;
    padding-left: 20px;
    box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
  }
  .content {
    width: 100%;
    min-height: calc(100% - 90px);
    margin-top: 20px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    padding: 20px 30px;
    position: relative;
    .control {
      margin-bottom: 25px;
    }
    .el-table {
      margin-bottom: 50px;
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
    .el-dialog {
      border-radius:10px;
      .el-dialog__header {
        border-bottom: 1px solid #D7D7D7;
        .el-dialog__title {
          font-size: 20px;
        }
      }
      .el-dialog__body {
        .title {
          color: #333333;
          font-size: 24px;
        }
        .details {
          padding: 20px;
          border: 1px solid #D7D7D7;
          border-radius: 10px;
          margin-top: 16px;
          p {
            margin: 0;
            margin-bottom: 5px;
            font-size: 16px;
            color: #333;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>
